import { createApi } from "@reduxjs/toolkit/query/react";
import { PageMeta, PaginatedData, Response, SupportListData } from "../../types";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export const SupportApi = createApi({
  reducerPath: "supportAPI",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["SupportList"],
  endpoints: (builder) => ({
    getSupportList: builder.query<Response<PaginatedData<SupportListData[]>>, PageMeta>(
      {
        // Getting seo query parameters
        query: (pageData: PageMeta) => ({
          url: `/support`,
          method: "GET",
          params: {
            limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
            page: pageData.page ?? 1,
            order: pageData.order ?? process.env.REACT_APP_ORDER,
            order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
            search: pageData.searchText,
          },
        }),
        providesTags: ["SupportList"],
      }
    ),

  

    // Getting seo query parameters by ID
    getSupportById: builder.query<Response<SupportListData>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/support/${id}`,
        method: "GET",
      }),
      providesTags: ["SupportList"],
    }),

    // Creating seo mutation
    addSupport: builder.mutation<Response<FormData>, FormData>({
      query: (mtuser: FormData) => ({
        url: `/support`,
        method: "POST",
        body: mtuser,
      }),
      invalidatesTags: ["SupportList"],
    }),

    // Updating seo mutation
    // editSeo: builder.mutation<Response<FormData>, FormData>({
    //   query: (data: FormData) => ({
    //     url: `/seo/${data.get("id")}`,
    //     method: "PUT",
    //     body: data,
    //   }),
    //   invalidatesTags: ["SupportList"],
    // }),

    // Deleting seo mutation
    deleteSupport: builder.mutation<Response<SupportListData>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/support/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SupportList"],
    }),


    bulkdeleteSupport: builder.mutation<
    Response<SupportListData[]>,
    string[] | undefined
  >({
    query: (ids: string[]) => ({
      url: `/support/bulk-delete/`,
      method: "DELETE",
      body: ids,
    }),
    invalidatesTags: ["SupportList"],
  }),

    // Getting seo query parameters
    getSupport: builder.query<Response, void>({
      query: () => ({
        url: `/support/get-support`,
        method: "GET",
      }),
      providesTags: ["SupportList"],
    }),

        // Responded/Not Responded support mutation
        RespondSupport: builder.mutation<
        Response<{ id: string; status: boolean }>,
        { id: string; status: boolean }
      >({
        query: (data: { id: string; status: boolean }) => ({
          url: `/support/change-status/${data.id}`,
          method: "PATCH",
          body: data,
        }),
        invalidatesTags: ["SupportList"],
      }),


  }),
});
export const {
  useAddSupportMutation,
  // useEditSupportMutation,
  useGetSupportByIdQuery,
  useGetSupportListQuery,
  useDeleteSupportMutation,
  useGetSupportQuery,
  useBulkdeleteSupportMutation,
  useRespondSupportMutation
} = SupportApi;
