import React from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton } from '@coreui/react';
import {
    debounce,
    Paper,
    StyledEngineProvider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmBox from '../../components/ConfirmBox/ConfirmBox';
import { SearchElement } from '../../components/Search/Search';
import { TableBodyElement } from '../../components/TableBody/TableBodyElement';
import { TableHeadElement } from '../../components/TableBody/TableHeadElement';
import {
    useDeleteObjectDetectionMutation,
    useObjectDetectionListQuery,
} from '../../redux-services/api/ObjectDetectionApi';
import { ColumnsType, Order, PageMeta, SupportListData } from '../../types';

const tableColumns: ColumnsType[] = [
    {
        id: 'id',
        sort: false,
        label: 'ID',
    },
    {
        id: 'title',
        sort: true,
        label: 'Title',
    },

    {
        id: 'filename',
        sort: true,
        label: 'Filename',
    },
    {
        id: 'createdAt',
        sort: true,
        label: 'Date',
    },

    {
        id: 'action',
        sort: false,
        label: '',
        style: { width: '15%' },
    },
];

export interface SortingData {
    title: string;
    filename: string;
    createdAt: string;
    updatedAt?: string;
}

//obj that repn state variable that stores the data of a message.
const ShowMessageData = {
    title: '',
    filename: '',
    createdAt: '',
    updatedAt: '',
};

let initialOrder: string = process.env.REACT_APP_ORDER as string;
const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: '',
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
};

const ObjectDetection: React.FunctionComponent = (props) => {
    let navigate = useNavigate();
    let query = useLocation();
    const [page, setPage] = useState<number>(1);
    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData,
    );
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [search, setSearch] = useState<string>('');
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number,
    );
    const [seoListState, setSeoListState] = useState<PageMeta>(initialState);
    const [isClose, setIsClose] = useState<boolean>(false);
    const [Id, setId] = useState<string>('');

    const { isSuccess, isLoading, data } = useObjectDetectionListQuery({
        per_page: seoListState.per_page ?? rowsPerPage,
        page: query.state ?? seoListState.page,
        order: seoListState.order ?? order,
        order_by: seoListState.order_by ?? orderBy,
        searchText: seoListState.searchText ?? search,
    });

    const [deleteObjectDetection, result] = useDeleteObjectDetectionMutation();

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage + 1);
        setSeoListState({ ...seoListState, page: newPage + 1 });
        // Condition for manage state of table list.
        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setSeoListState({
            ...seoListState,
            per_page: parseInt(event.target.value),
            page: 1,
        });
        setPage(1);
    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);
        setSeoListState({
            ...seoListState,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData,
    ) => {
        if (property !== ('action' as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const searchHandler = useMemo(() => {
        return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
            setSeoListState({
                page: 1,
                per_page: seoListState.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, []);

    const confirmBoxClose = (res: boolean) => {
        setIsClose(res);
        if (res) {
            setIsClose(false);
            deleteObjectDetection(Id);
        } else {
            setIsClose(res);
        }
    };

    return (
        <CRow>
            <CCol xs={12}>
                <CCard className="mb-4">
                    <CCardHeader
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                        <h5>
                            <strong>Object Detection Files List</strong>
                        </h5>
                        <Tooltip title="Add Object Detection File" arrow>
                            <CButton
                                size="sm"
                                color="primary"
                                variant="outline"
                                onClick={() => navigate("/object-detection/create")}
                            >
                                Add Object Detection File
                            </CButton>
                        </Tooltip>
                    </CCardHeader>
                    <CCardBody className="bg-transparent">
                        <SearchElement
                            searchFn={searchHandler}
                            searchTag={'Search By Title...'}
                        />
                        <StyledEngineProvider>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table bordered"
                                    className="table-bordered table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableHeadElement
                                                setHead={tableColumns ? tableColumns : []}
                                                order={order}
                                                orderBy={orderBy}
                                                sortFn={(event, id: string) =>
                                                    createSortHandler(event, id as keyof SortingData)
                                                }
                                            />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableBodyElement
                                            selectedColumns={['title', 'filename', 'createdAt']}
                                            setData={data?.response.data ? data?.response.data : []}
                                            editFn={(id) =>
                                                navigate(`/object-detection/edit/${id}`, {
                                                    state: page,
                                                })
                                            }
                                            showAction={true}
                                            deleteFn={(id) => {
                                                setIsClose(true);
                                                setId(id);
                                            }}
                                            pageData={{
                                                limit:
                                                    rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                                page: data?.response?.page as number,
                                            }}
                                        />
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    component="div"
                                    count={data?.response.total ?? 0}
                                    page={data?.response.page ?? 0}
                                    rowsPerPageOptions={[20, 50, 100]}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={
                                        rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                                    }
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </StyledEngineProvider>
                    </CCardBody>
                </CCard>
            </CCol>
            <ConfirmBox
                title="Object Detection Confirmation"
                message="Are you sure, you want to delete this File?"
                open={isClose}
                onClose={confirmBoxClose}
            />
        </CRow>
    );
};

export default ObjectDetection;
