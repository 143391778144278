import { createApi } from '@reduxjs/toolkit/query/react';
import {
  Response,
} from '../../types';
import { axiosBaseQuery } from './AxiosBaseQuery';

export const ManageUIApi = createApi({
  reducerPath: 'manageUIAPI',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ManageUI'],
  endpoints: (builder) => ({
    getManageUIData: builder.query<Response<any>, any>({
      query: (getDetail: string) => ({
        url: `/manage-ui`,
        method: 'GET',
      }),
      providesTags: ['ManageUI'],
    }),

    editManageUI: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/manage-ui/edit/${data.get("id")}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["ManageUI"],
    }),
  }),
});
export const {
  useGetManageUIDataQuery,
  useEditManageUIMutation,
} = ManageUIApi;
