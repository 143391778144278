import React, { useState, useEffect, useCallback } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CRow,
} from '@coreui/react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';

import { useCreateProductsMutation, useEditProductsMutation, useGetProductsByIdQuery } from '../../redux-services';

export interface FileUploadState {
  name: string;
  description: string;
  link: string;
  fileType: any;
  files: any;
  files_url: any;
  urls?: any;
  deleted_files?: any;
}

const AddEditProducts = () => {
  const initialFormState: FileUploadState = {
    name: '',
    description: '',
    link: '',
    fileType: [],
    files: [],
    files_url: [],
    urls: [],
    deleted_files: [],
  };

  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<FileUploadState>(initialFormState);

  const [createProduct, result] = useCreateProductsMutation();

  const [editProduct, editResult] = useEditProductsMutation();

  const { data: ProductData } = useGetProductsByIdQuery(id, { skip: !id });

  useEffect(() => {
    if (ProductData?.status === 200) {
      let data: any = ProductData?.response;
      let fileType = [];
      const imagesType: any = {
        "jpg": "image/jpeg",
        "jpeg": "image/jpeg",
        "png": "image/png",
        "gif": "image/gif",
        "bmp": "image/bmp",
        "webp": "image/webp",
        "ico": "image/x-icon",
        "svg": "image/svg+xml",
        "tiff": "image/tiff",
        "heif": "image/heif",
        "heic": "image/heic",
      };

      const videoType: any = {
        "mp4": "video/mp4",
        "ogg": "video/ogg",
        "webm": "video/webm",
        "avi": "video/x-msvideo",
        "3gp": "video/3gpp",
        "3g2": "video/3gpp2",
        "mkv": "video/x-matroska",
        "mov": "video/quicktime",
        "flv": "video/x-flv",
        "wmv": "video/x-ms-wmv"
      };

      if (data?.files?.length > 0) {
        for (let fileData of data?.files) {
          const url = fileData?.fullUrl;
          const extension: any = url.split('.').pop().toLowerCase();

          if (imagesType[extension] !== undefined) {
            fileType.push({ type: 'image', fileType: imagesType[extension] });
          }
          if (videoType[extension] !== undefined) {
            fileType.push({ type: 'video', fileType: videoType[extension] });
          }
        }
      }

      setFormData({
        ...formData,
        name: data?.name ?? ' ',
        description: data.description ?? " ",
        link: data.link ?? '',
        files: data?.files?.length ? data.files.map((image: any) => image.path) : [],
        files_url: data?.encodedUrl?.length ? data.encodedUrl : [],
        urls: data.files?.length ? data?.files.map((image: any) => image.fullUrl) : [],
        fileType: fileType ?? [],
        deleted_files: [],
      });
    }
  }, [ProductData]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }, [formData]);

  const handleChangeImage = useCallback((event: any) => {
    event.preventDefault();
    let setFileUrl: string[] = [];
    let deletedImgArr = [];
    let fileType = [];
    let file = Array.from(event.target.files);
    for (const element of event.target.files) {
      const imageTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
        "image/x-icon",
        "image/svg+xml",
        "image/tiff",
        "image/heif",
        "image/heic",
        "image/avif"
      ];
      const videoTypes = [
        "video/mp4",
        "video/ogg",
        "video/webm",
        "video/x-msvideo",
        "video/3gpp",
        "video/3gpp2",
        "video/x-matroska",
        "video/quicktime",
        "video/x-flv",
        "video/x-ms-wmv"
      ];

      if (imageTypes.includes(element.type)) {
        fileType.push({ type: 'image', fileType: element.type });
      }
      if (videoTypes.includes(element.type)) {
        fileType.push({ type: 'video', fileType: element.type });
      }

      setFileUrl.push(URL.createObjectURL(element));
    }
    if (formData.urls?.length) {
      deletedImgArr.push(formData.urls[0]);
    }
    const thumbnailElement = document.getElementById('files') as HTMLInputElement | null;
    if (thumbnailElement) {
      setFormData({
        ...formData,
        files: [...file],
        files_url: [...setFileUrl],
        deleted_files: [...deletedImgArr],
        fileType: fileType,
      });
    }
  }, [formData]);

  const handleRemoveImage = useCallback((index: number) => {
    const updatedImages = [...formData.files];
    updatedImages.splice(index, 1);

    let deletedImgArr = [];
    if (formData.urls?.length) {
      deletedImgArr.push(formData.urls[index]);
    }

    const updatedFiles = [...formData.files_url];
    updatedFiles.splice(index, 1);

    setFormData({
      ...formData,
      files: updatedImages,
      files_url: updatedFiles,
      deleted_files: [...formData.deleted_files, ...deletedImgArr],
    });
  }, [formData]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let data = new FormData;
    for (const item of formData.files) {
      data.append("files", item);
    }
    if (id) {
      for (const item of formData.deleted_files) {
        data.append("deleted_files", item);
      }
    }
    data.append("name", formData.name);
    data.append("description", formData.description);
    data.append("link", formData.link);
    data.append('id', id ? id : null as any);

    id ? editProduct(data) : createProduct(data);

  };

  if (result.isSuccess && result.data.status === 200) {
    navigate("/products");
  }

  if (editResult.isSuccess && editResult.data.status === 200) {
    navigate("/products");
  }

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>{id ? 'Edit Product' : 'Add Product'}</strong>
          </CCardHeader>
          <CCardBody className="bg-transparent">
            <CForm onSubmit={handleSubmit}>
              <CRow className="g-3">
                <CCol xs={6}>
                  <CFormLabel htmlFor="boh-name">Product Name *</CFormLabel>
                  <CFormInput
                    id="boh-name"
                    type="text"
                    placeholder="Product Name"
                    value={formData.name}
                    name="name"
                    required
                    feedbackInvalid="Please provide a Product Name."
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="boh-name">Link</CFormLabel>
                  <CFormInput
                    id="boh-name"
                    type="url"
                    placeholder="Link"
                    value={formData.link}
                    name="link"
                    feedbackInvalid="Please provide a Link."
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs={12}>
                  <CFormLabel htmlFor="boh-name">Product Description</CFormLabel>
                  <CFormTextarea
                    placeholder="Please enter a product description"
                    value={formData.description}
                    name="description"
                    rows={6}
                    maxLength={200}
                    feedbackInvalid="Please provide a Product Description."
                    onChange={handleChange}
                  ></CFormTextarea>
                </CCol>
                <CCol xs={12}>
                  <CFormLabel htmlFor="boh-name">File Upload *</CFormLabel>
                  <CFormInput
                    id="files"
                    type="file"
                    placeholder="File Upload"
                    name="files"
                    required={formData?.files?.length === 0 ? true : false}
                    accept="image/*,video/mp4,video/x-m4v,video/*,.gif"
                    feedbackInvalid="Please provide a File."
                    onChange={handleChangeImage}
                  />
                </CCol>
                {formData?.files_url?.length ? formData.files_url.map((file: string, index: number) => (
                  <div key={index} style={{ display: "flex" }}>
                    {
                      formData.fileType?.length && formData.fileType[index]?.type === "video" ?
                        <video controls width="400" height="250">
                          <source src={file} type={formData.fileType[index]?.fileType} />
                          Your browser does not support the video tag.
                        </video> : ""
                    }
                    {
                      formData.fileType?.length && formData.fileType[index]?.type === "image" ?
                        <img src={file} width="400" height="250" /> : ""
                    }
                    <div onClick={() => handleRemoveImage(index)} style={{ cursor: "pointer", color: "#fff", backgroundColor: "red", borderRadius: "20px", height: "25px" }} title='Remove File'><ClearIcon /></div>
                  </div>
                )) : ''}
                <CCol xs={12}>
                  <CButton type="submit" color="primary" className="px-4 m-2">
                    Submit
                  </CButton>
                  <Link className="btn btn-danger px-4" to="/products">
                    Cancel
                  </Link>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default AddEditProducts