import React from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import {
  debounce,
  Paper,
  StyledEngineProvider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmBox from '../../components/ConfirmBox/ConfirmBox';
import { SearchElement } from '../../components/Search/Search';
import { TableBodyElement } from '../../components/TableBody/TableBodyElement';
import { TableHeadElement } from '../../components/TableBody/TableHeadElement';
import {
  useDeleteSupportMutation,
  useGetSupportListQuery,
  useRespondSupportMutation,
} from '../../redux-services/api/SupportApi';
import { ColumnsType, Order, PageMeta, SupportListData } from '../../types';

const tableColumns: ColumnsType[] = [
  {
    id: 'id',
    sort: false,
    label: '',
  },

  {
    id: 'id',
    sort: false,
    label: 'ID',
  },
  {
    id: 'name',
    sort: true,
    label: 'Name',
  },

  {
    id: 'email',
    sort: true,
    label: 'Email',
  },
  {
    id: 'createdAt',
    sort: true,
    label: 'Date',
  },

  {
    id: 'action',
    sort: false,
    label: '',
    style: { width: '15%' },
  },
];

export interface SortingData {
  // id: string;
  name: string;
  email: string;
  // : string;
  // Date: string;
  // title: string;
  // dateOfJoining: string;
  createdAt: string;
  updatedAt?: string;
}

//obj that repn state variable that stores the data of a message.
const ShowMessageData = {
  name: '',
  email: '',
  subject: '',
  description: '',
  createdAt: '',
  updatedAt: '',
};

let initialOrder: string = process.env.REACT_APP_ORDER as string;
const initialState: PageMeta = {
  page: 1,
  per_page: Number(process.env.REACT_APP_PER_PAGE),
  searchText: '',
  order: process.env.REACT_APP_ORDER,
  order_by: process.env.REACT_APP_ORDER_BY,
};

const Support: React.FunctionComponent = (props) => {
  let navigate = useNavigate();
  let query = useLocation();
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData,
  );
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const [search, setSearch] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState.per_page as number,
  );
  const [seoListState, setSeoListState] = useState<PageMeta>(initialState);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [Id, setId] = useState<string>('');

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [enquiryMessage, setEnquiryMessage] =
    useState<SupportListData>(ShowMessageData);

  const { isSuccess, isLoading, data } = useGetSupportListQuery({
    per_page: seoListState.per_page ?? rowsPerPage,
    page: query.state ?? seoListState.page,
    order: seoListState.order ?? order,
    order_by: seoListState.order_by ?? orderBy,
    searchText: seoListState.searchText ?? search,
  });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1);
    setSeoListState({ ...seoListState, page: newPage + 1 });
    // Condition for manage state of table list.
    if (query.state !== null) {
      navigate(query.pathname, {});
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setSeoListState({
      ...seoListState,
      per_page: parseInt(event.target.value),
      page: 1,
    });
    setPage(1);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    setSeoListState({
      ...seoListState,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData,
  ) => {
    if (property !== ('action' as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const searchHandler = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setSeoListState({
        page: 1,
        per_page: seoListState.per_page,
        order: initialOrder,
        searchText: event.target.value,
      });
    }, 800);
  }, []);

  const confirmBoxClose = (res: boolean) => {
    setIsClose(res);
    if (res) {
      setIsClose(false);
      deleteSeo(Id);
    } else {
      setIsClose(res);
    }
  };

  const handleClose = (res: boolean) => {
    setConfirmation(res);
    if (res) {
      setConfirmation(false);
    } else {
      setConfirmation(res);
    }
  };

  // const showMessage = (content: SupportListData) => {
  //   setConfirmation(true);
  //   setEnquiryMessage(content);
  // };

  const [deleteSeo] = useDeleteSupportMutation();
  // const [bulkdeleteSeo] = useBulkdeleteSupportMutation();
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);

  const [RespondorNot, editStatus] = useRespondSupportMutation();

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds =
        data?.response.data?.map((row: any) => {
          return row._id;
        }) ?? [];
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    if (event.target.checked) {
      setSelected((previousSelected) => [...previousSelected, id]);
    } else {
      setSelected((previousSelected) =>
        previousSelected.filter((selectedId) => selectedId !== id),
      );
    }
  };

  // const changeStatus = (id: string, status: boolean) => {
  //   RespondorNot({ id, status });
  // };
  const [open, setOpen] = useState<Boolean>(false);
  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <h5>
              <strong>Support List</strong>
            </h5>
          </CCardHeader>
          <CCardBody className="bg-transparent">
            <SearchElement
              searchFn={searchHandler}
              searchTag={'Search User Requests  ...'}
            />
            <StyledEngineProvider>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table bordered"
                  className="table-bordered table"
                >
                  <TableHead>
                    <TableRow>
                      <TableHeadElement
                        setHead={tableColumns ? tableColumns : []}
                        order={order}
                        orderBy={orderBy}
                        sortFn={(event, id: string) =>
                          createSortHandler(event, id as keyof SortingData)
                        }
                        onSelectAllClick={handleSelectAllClick}
                        isAllSelected={
                          selected.length > 0 &&
                          selected.length === (data?.response.data?.length ?? 0)
                        }
                        isIntermediateState={
                          selected.length > 0 &&
                          selected.length < (data?.response.data?.length ?? 0)
                        }
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableBodyElement
                      selectedColumns={['name', 'email', 'createdAt']}
                      setData={data?.response.data ? data?.response.data : []}
                      respondFn={(e) => console.log(e)}
                      styleFn={'25%'}
                      dropFn={(index) => {
                        setIsOpen(false);
                      }}
                      deleteFn={(id) => {
                        setIsClose(true);
                        setId(id);
                      }}
                      showAction={true}
                      pageData={{
                        limit:
                          rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                        page: data?.response?.page as number,
                      }}
                      selected={selected}
                      handleClick={handleClick}
                    />
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={data?.response.total ?? 0}
                  page={data?.response.page ?? 0}
                  rowsPerPageOptions={[20, 50, 100]}
                  onPageChange={handleChangePage}
                  rowsPerPage={
                    rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                  }
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </StyledEngineProvider>
          </CCardBody>
        </CCard>
      </CCol>
      {/* <ViewBox
          title="Request Message"
          message={enquiryMessage}
          open={confirmation}
          onClose={handleClose}
        /> */}
      <ConfirmBox
        title="Support List Confirmation"
        message="Are you sure, you want to delete this user?"
        open={isClose}
        onClose={confirmBoxClose}
      />
    </CRow>
  );
};

export default Support;
