import { createApi } from '@reduxjs/toolkit/query/react';
import {
    PageMeta,
    PaginatedData,
    Response,
} from '../../types';
import { axiosBaseQuery } from './AxiosBaseQuery';

export const PlayerApi = createApi({
    reducerPath: 'playerAPI',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Player'],
    endpoints: (builder) => ({

        playerList: builder.query<Response<PaginatedData<any>>,PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/player/list`,
                method: "GET",
                params: {
                    limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    order: pageData.order ?? process.env.REACT_APP_ORDER,
                    order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                    startDate: pageData.startDate ?? "",
                    endDate: pageData.endDate ?? "",
                    login_id: pageData.login_id ?? "",
                },
            }),
            providesTags: ["Player"],
        }),
    })
});

export const {
    usePlayerListQuery,
} = PlayerApi;
