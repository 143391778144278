import { RouteInfo } from "./CommonTypes";
import ChangePassword from "./views/changePassword/Change-Password";
import Dashboard from "./views/dashboard/Dashboard";
import { Users } from "./views/users/users";
import Support from "./views/support";
import ManageUIEditor from "./views/manageUI";
import FileUploadEditor from "./views/ObjectDetectFileUpload/FileUploadEditor";
import ObjectDetection from "./views/ObjectDetectFileUpload";
import AddEditTopic from "./views/topics/AddEditTopic";
import Topic from "./views/topics";
import AppStoreRedirect from "./views/appStoreRedirect/AppStoreRedirect";
import Products from "./views/products";
import AddEditProducts from "./views/products/AddEditProducts";
import { Player } from "./views/Player";

const AdminRouting: RouteInfo[] = [
  /* { path: '/', exact: true, name: 'Home' }, */

  { path: "/dashboard", exact: true, name: "Dashboard", element: Dashboard },

  {
    path: "/change-password",
    name: "Change Password",
    element: ChangePassword,
  },
  { path: "/users", name: "Users List", element: Users },

  // { path: "/users/player/:id", name: "Users Player", element: Player },

  { path: "/support", name: "Support List", element: Support },

  { path: "/manage-ui", name: "Manage UI", element: ManageUIEditor },

  { path: "/object-detection", name: "Object Detection Files List", element: ObjectDetection },

  { path: "/object-detection/create", name: "Create Object Detection", element: FileUploadEditor },

  { path: "/object-detection/edit/:id", name: "Edit Object Detection", element: FileUploadEditor },

  { path: "/topic/add", name: "Add Notification", element: AddEditTopic },
  { path: "/topic/edit/:id", name: "Add Notification", element: AddEditTopic },
  { path: "/topic", name: "Notification List", element: Topic },

  { path: "/iosapp", exact: true, name: "App Store Redirect", element: AppStoreRedirect },

  { path: "/products", name: "Product List", element: Products },

  { path: "/products/create", name: "Create Product", element: AddEditProducts },

  { path: "/products/edit/:id", name: "Edit Product", element: AddEditProducts },
];
export default AdminRouting;
