import React, { useState, useEffect, useCallback } from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CRow,
} from '@coreui/react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { useCreateTopicMutation, useEditTopicMutation, useGetTopicByIdQuery } from '../../redux-services';

export interface FileUploadState {
    name: string;
    title: string;
    message: string;
}

const AddEditTopic = () => {
    const initialFormState: FileUploadState = {
        name: '',
        title: '',
        message: '',
    };

    const { id } = useParams();
    const navigate = useNavigate();

    const [formData, setFormData] = useState<FileUploadState>(initialFormState);
    const [isValid, setIsValid] = useState(true);
    const [createTopic, result] = useCreateTopicMutation();

    const [editTopic, editResult] = useEditTopicMutation();

    const { data: TopicData } = useGetTopicByIdQuery(id, { skip: !id });


    useEffect(() => {
        if (TopicData?.status === 200) {
            let data: any = TopicData?.response;
            setFormData({
                ...formData,
                name: data?.name ?? '',
                title: data?.title ?? '',
                message: data?.message ?? ''
            });
        }
    }, [TopicData]);

    const validateTopic = (topic: string) => {
        const regex = /^[a-zA-Z0-9-_.~%]{1,900}$/;
        return regex.test(topic);
    };
    
    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setIsValid(validateTopic(value.trim()));
            setFormData({ ...formData, [name]: value.trim() });
        } else {
            setFormData({ ...formData, [name]: value.trimStart() });
        }
    }, [formData]);


    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (isValid) {
            let data = { ...formData, id: id ? id : null as any };
            id ? editTopic(data) : createTopic(data);
        }
    };

    if (result.isSuccess && result.data.status === 200) {
        navigate("/topic");
    }

    if (editResult.isSuccess && editResult.data.status === 200) {
        navigate("/topic");
    }

    return (
        <CRow>
            <CCol xs={12}>
                <CCard className="mb-4">
                    <CCardHeader>
                        <strong>{id ? 'Edit Notification' : 'Add Notification'}</strong>
                    </CCardHeader>
                    <CCardBody className="bg-transparent">
                        <CForm onSubmit={handleSubmit}>
                            <CRow className="g-3">
                                <CCol xs={6}>
                                    <CFormLabel htmlFor="boh-name">Name*</CFormLabel>
                                    <CFormInput
                                        id="boh-name"
                                        type="text"
                                        placeholder="Name"
                                        value={formData.name}
                                        name="name"
                                        required
                                        readOnly={id ? true : false}
                                        feedbackInvalid="Please provide a name."
                                        onChange={handleChange}
                                    />
                                    {!isValid && <p className="mt-2 alert alert-danger">The name does not follow the standard format used for topic names.</p>}
                                </CCol>
                                <CCol xs={6}>
                                    <CFormLabel htmlFor="boh-name">Title*</CFormLabel>
                                    <CFormInput
                                        id="boh-name"
                                        type="text"
                                        placeholder="Title"
                                        value={formData.title}
                                        name="title"
                                        required
                                        feedbackInvalid="Please provide a title."
                                        onChange={handleChange}
                                    />
                                </CCol>
                                <CCol xs={6}>
                                    <CFormLabel htmlFor="boh-name">Message*</CFormLabel>
                                    <CFormInput
                                        id="boh-name"
                                        type="text"
                                        placeholder="Message"
                                        value={formData.message}
                                        name="message"
                                        required
                                        feedbackInvalid="Please provide a message."
                                        onChange={handleChange}
                                    />
                                </CCol>
                                <CCol xs={12}>
                                    <CButton type="submit" color="primary" className="px-4 m-2">
                                        Submit
                                    </CButton>
                                    <Link className="btn btn-danger px-4" to="/topic">
                                        Cancel
                                    </Link>
                                </CCol>
                            </CRow>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    )
}

export default AddEditTopic