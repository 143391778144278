import { createApi } from '@reduxjs/toolkit/query/react';
import {
    PageMeta,
    PaginatedData,
    Response,
    Products,
} from '../../types';
import { axiosBaseQuery } from './AxiosBaseQuery';

export const ProductsApi = createApi({
    reducerPath: 'productsAPI',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Products'],
    endpoints: (builder) => ({

        productsList: builder.query<Response<PaginatedData<Products[]>>,PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/products`,
                method: "GET",
                params: {
                    limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    order: pageData.order ?? process.env.REACT_APP_ORDER,
                    order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                    startDate: pageData.startDate ?? "",
                    endDate: pageData.endDate ?? "",
                },
            }),
            providesTags: ["Products"],
        }),

        createProducts: builder.mutation<Response<any>, FormData>({
            query: (data: FormData) => ({
                url: `/products/create`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Products"],
        }),

        getProductsById: builder.query<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/products/get/${id}`,
                method: "GET",
            }),
            providesTags: ["Products"],
        }),

        editProducts: builder.mutation<Response<any>, FormData>({
            query: (data: FormData) => ({
                url: `/products/edit/${data.get("id")}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Products"],
        }),

        deleteProducts: builder.mutation<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/products/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Products"],
        }),
    }),
});
export const {
    useProductsListQuery,
    useCreateProductsMutation,
    useGetProductsByIdQuery,
    useEditProductsMutation,
    useDeleteProductsMutation
} = ProductsApi;
