import React, { useEffect, SyntheticEvent } from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton, CFormLabel, CForm, CFormInput } from '@coreui/react';
import {
    debounce,
    Paper,
    StyledEngineProvider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmBox from '../../components/ConfirmBox/ConfirmBox';
import { SearchElement } from '../../components/Search/Search';
import { TableBodyElement } from '../../components/TableBody/TableBodyElement';
import { TableHeadElement } from '../../components/TableBody/TableHeadElement';
import {
    useDeleteTopicMutation,
    useNotifyAllMutation,
    useNotifyTopicMutation,
    useTopicListQuery,
} from '../../redux-services';
import { ColumnsType, Order, PageMeta, SupportListData } from '../../types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import TextField from '@mui/material/TextField';

const tableColumns: ColumnsType[] = [
    {
        id: 'id',
        sort: false,
        label: 'ID',
    },
    {
        id: 'name',
        sort: true,
        label: 'Name',
    },
    {
        id: 'title',
        sort: true,
        label: 'Title',
    },
    {
        id: 'message',
        sort: true,
        label: 'Message',
    },
    {
        id: 'action',
        sort: false,
        label: '',
        style: { width: '15%' },
    },
];

export interface SortingData {
    title: string;
    filename: string;
    createdAt: string;
    updatedAt?: string;
}

//obj that repn state variable that stores the data of a message.
const ShowMessageData = {
    title: '',
    filename: '',
    createdAt: '',
    updatedAt: '',
};

let initialOrder: string = process.env.REACT_APP_ORDER as string;
const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: '',
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
};

const Topic: React.FunctionComponent = () => {
    let navigate = useNavigate();
    let query = useLocation();
    const [page, setPage] = useState<number>(1);
    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData,
    );
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [search, setSearch] = useState<string>('');
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number,
    );
    const [seoListState, setSeoListState] = useState<PageMeta>(initialState);
    const [isClose, setIsClose] = useState<boolean>(false);
    const [Id, setId] = useState<string>('');
    const [notifyModel, setNotifyModel] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        message: '',
    });

    const [notifyAll, notifyAllResult] = useNotifyAllMutation();

    const { isSuccess, isLoading, data } = useTopicListQuery({
        per_page: seoListState.per_page ?? rowsPerPage,
        page: query.state ?? seoListState?.page,
        order: seoListState.order ?? order,
        order_by: seoListState.order_by ?? orderBy,
        searchText: seoListState.searchText ?? search,
    });

    const [deleteTopic, result] = useDeleteTopicMutation();

    const [notifyTopic, notifyResult] = useNotifyTopicMutation();

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage + 1);
        setSeoListState({ ...seoListState, page: newPage + 1 });
        // Condition for manage state of table list.
        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setSeoListState({
            ...seoListState,
            per_page: parseInt(event.target.value),
            page: 1,
        });
        setPage(1);
    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);
        setSeoListState({
            ...seoListState,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData,
    ) => {
        if (property !== ('action' as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const searchHandler = useMemo(() => {
        return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
            setSeoListState({
                page: 1,
                per_page: seoListState.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, []);

    const confirmBoxClose = (res: boolean) => {
        setIsClose(res);
        if (res) {
            setIsClose(false);
            deleteTopic(Id);
        } else {
            setIsClose(res);
        }
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.trimStart() });
    };

    const handleSubmit = (event: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
        event.preventDefault();
        notifyAll(formData);
    };

    useEffect(() => {
        if (notifyAllResult.isSuccess && notifyAllResult.data.status === 200) {
            setFormData({ title: '', message: '' });
            setNotifyModel(false);
        }
    }, [notifyAllResult])
    
   

    return (
        <CRow>
            <CCol xs={12}>
                <CCard className="mb-4">
                    <CCardHeader
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                        <h5>
                            <strong>Notification List</strong>
                        </h5>
                        <div>
                            <Tooltip title="Notify All" arrow style={{ margin: "0px 10px" }}>
                                <CButton
                                    size="sm"
                                    color="primary"
                                    variant="outline"
                                    onClick={() => setNotifyModel(true)}
                                >
                                    Notify All
                                </CButton>
                            </Tooltip>
                            <Tooltip title="Add Notification" arrow>
                                <CButton
                                    size="sm"
                                    color="primary"
                                    variant="outline"
                                    onClick={() => navigate("/topic/add")}
                                >
                                    Add Notification
                                </CButton>
                            </Tooltip>
                        </div>
                    </CCardHeader>
                    <CCardBody className="bg-transparent">
                        <SearchElement
                            searchFn={searchHandler}
                            searchTag={'Search By Name...'}
                        />
                        <StyledEngineProvider>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table bordered"
                                    className="table-bordered table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableHeadElement
                                                setHead={tableColumns ? tableColumns : []}
                                                order={order}
                                                orderBy={orderBy}
                                                sortFn={(event, id: string) =>
                                                    createSortHandler(event, id as keyof SortingData)
                                                }
                                            />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableBodyElement
                                            selectedColumns={['name', 'title', 'message']}
                                            setData={data?.response?.data ? data?.response.data : []}
                                            topicNotifyFn={(id) => {
                                                notifyTopic(id);
                                            }}
                                            topicEditFn={(id) => {
                                                navigate(`/topic/edit/${id}`, {
                                                    state: page,
                                                })
                                            }}
                                            showAction={true}
                                            topicDeleteFn={(id) => {
                                                setIsClose(true);
                                                setId(id);
                                            }}
                                            pageData={{
                                                limit:
                                                    rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                                page: data?.response?.page as number,
                                            }}
                                        />
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    component="div"
                                    count={data?.response?.total ?? 0}
                                    page={data?.response?.page ?? 0}
                                    rowsPerPageOptions={[20, 50, 100]}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={
                                        rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                                    }
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </StyledEngineProvider>
                    </CCardBody>
                </CCard>
            </CCol>
            {notifyModel &&
                <Dialog onClose={() => setNotifyModel(false)} open={notifyModel} fullWidth>
                    <DialogTitle>
                        Notify All Message
                        <IconButton
                            aria-label="close"
                            onClick={() => setNotifyModel(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme: any) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <CForm onSubmit={handleSubmit}>

                        <DialogContent dividers>
                            <DialogContentText id="alert-dialog-description">
                                <CRow className="g-3">
                                    <CCol xs={12}>
                                        <CFormLabel htmlFor="boh-name">Title</CFormLabel>
                                        <TextField
                                            type="text"
                                            value={formData.title}
                                            name="title"
                                            fullWidth
                                            required
                                            onChange={handleChange}
                                        />
                                    </CCol>
                                    <CCol xs={12}>
                                        <CFormLabel htmlFor="boh-name">Message</CFormLabel>
                                        <TextField
                                            fullWidth
                                            multiline
                                            name="message"
                                            required
                                            InputProps={{
                                                rows: 3
                                            }}
                                            value={formData.message}
                                            onChange={handleChange}
                                        />

                                    </CCol>
                                </CRow>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="error" onClick={() => setNotifyModel(false)}>
                                No
                            </Button>
                            <Button type="submit" variant="outlined" color="success" autoFocus>
                                Send
                            </Button>
                        </DialogActions>
                    </CForm>
                </Dialog>
            }
            <ConfirmBox
                title="Notification Confirmation"
                message="Are you sure, you want to delete this notification?"
                open={isClose}
                onClose={confirmBoxClose}
            />
        </CRow>
    );
};

export default Topic;
