import { Link } from "react-router-dom";
import { useGetDashboardQuery } from "../../redux-services/api/DashboardApi";
import { SupportAgentOutlined } from "@mui/icons-material";

const Dashboard = () => {
  
  const { data }: any = useGetDashboardQuery();// useGetDashboardQuery is a hook return 3 param isSuccess, isLoading, and data
  // they tells whether the data is properly came from Api 
  return (
    <div className="row">
      <div className="col-md-3">
        <div className="card-body box-outer">
          <h3>Users</h3>
          <div className="d-flex align-items-end justify-content-between mt-1">
            <div>
              <h4>{data && data.response.users}</h4>
              <Link to="/users">View all Users</Link>
            </div>
            <div className="icon-outer">
              <Link to="/users">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="users"
                  className="svg-inline--fa fa-phone"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card-body box-outer services-outer">
          <h3>SUPPORT</h3>
          <div className="d-flex align-items-end justify-content-between mt-1">
            <div>
              <h4>{data && data.response.support}</h4>
              <Link to="/support">View all Support Requests</Link>
            </div>
            <div className="icon-outer">
              <Link to="/support">
                <SupportAgentOutlined color="action" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
