import { createApi } from '@reduxjs/toolkit/query/react';
import {
    PageMeta,
    PaginatedData,
    Response,
    TopicData,
} from '../../types';
import { axiosBaseQuery } from './AxiosBaseQuery';

export const TopicApi = createApi({
    reducerPath: 'topicAPI',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['TopicTags'],
    endpoints: (builder) => ({

        topicList: builder.query<Response<PaginatedData<TopicData[]>>, PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/topic`,
                method: "GET",
                params: {
                    limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    order: pageData.order ?? process.env.REACT_APP_ORDER,
                    order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                    startDate: pageData.startDate ?? "",
                    endDate: pageData.endDate ?? "",
                },
            }),
            providesTags: ["TopicTags"],
        }),

        createTopic: builder.mutation<Response<any>, TopicData>({
            query: (data: TopicData) => ({
                url: `/topic`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["TopicTags"],
        }),

        getTopicById: builder.query<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/topic/${id}`,
                method: "GET",
            }),
            providesTags: ["TopicTags"],
        }),

        editTopic: builder.mutation<Response<any>, TopicData>({
            query: (data: TopicData) => ({
                url: `/topic/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["TopicTags"],
        }),

        deleteTopic: builder.mutation<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/topic/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["TopicTags"],
        }),

        notifyTopic: builder.mutation<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/topic/notify/${id}`,
                method: "POST",
            }),
            invalidatesTags: ["TopicTags"],
        }),

        notifyAll: builder.mutation<Response<any>, TopicData>({
            query: (data: TopicData) => ({
                url: `/topic/notifyall`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["TopicTags"],
        }),
    }),
});
export const {
    useTopicListQuery,
    useCreateTopicMutation,
    useGetTopicByIdQuery,
    useEditTopicMutation,
    useDeleteTopicMutation,
    useNotifyTopicMutation,
    useNotifyAllMutation,
} = TopicApi;
