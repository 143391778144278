import React, { useState, useEffect, useCallback } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from '@coreui/react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import CIcon from '@coreui/icons-react';
import { cilFile } from '@coreui/icons';

import { useCreateObjectDetectionMutation, useEditObjectDetectionMutation, useGetObjectDetectionByIdQuery } from '../../redux-services/api/ObjectDetectionApi';

export interface FileUploadState {
  title: string;
  filename: string;
  files: any;
  files_url: any;
  urls?: any;
  deleted_files?: any;
}

const FileUploadEditor = () => {
  const initialFormState: FileUploadState = {
    title: '',
    filename: '',
    files: [],
    files_url: [],
    urls: [],
    deleted_files: [],
  };

  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<FileUploadState>(initialFormState);

  const [createObjectDetection, result] = useCreateObjectDetectionMutation();

  const [editObjectDetection, editResult] = useEditObjectDetectionMutation();

  const { data: ObjectDetectionData } = useGetObjectDetectionByIdQuery(id, { skip: !id });


  useEffect(() => {
    if (ObjectDetectionData?.status === 200) {
      let data: any = ObjectDetectionData?.response;
      setFormData({
        ...formData,
        title: data?.title ?? ' ',
        filename: data.filename ?? " ",
        files: data?.files?.length ? data.files.map((image: any) => image.path) : [],
        files_url: data?.encodedUrl?.length ? data.encodedUrl : [],
        urls: data.files?.length ? data?.files.map((image: any) => image.fullUrl) : [],
        deleted_files: [],
      });
    }
  }, [ObjectDetectionData]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }, [formData]);

  const handleChangeImage = useCallback((event: any) => {
    event.preventDefault();
    let setFileUrl: string[] = [];
    let deletedImgArr = [];
    let file = Array.from(event.target.files);
    for (const element of event.target.files) {
      setFileUrl.push(URL.createObjectURL(element));
    }
    if (formData.urls?.length) {
      deletedImgArr.push(formData.urls[0]);
    }
    const thumbnailElement = document.getElementById('files') as HTMLInputElement | null;
    if (thumbnailElement) {
      const filename = thumbnailElement.value.replace('C:\\fakepath\\', '');
      setFormData({
        ...formData,
        filename: filename,
        files: [...file],
        files_url: [...setFileUrl],
        deleted_files: [...deletedImgArr]
      });
    }
  }, [formData]);

  const handleRemoveImage = useCallback((index: number) => {
    const updatedImages = [...formData.files];
    updatedImages.splice(index, 1);

    let deletedImgArr = [];
    if (formData.urls?.length) {
      deletedImgArr.push(formData.urls[index]);
    }

    const updatedFiles = [...formData.files_url];
    updatedFiles.splice(index, 1);
    setFormData({
      ...formData,
      files: updatedImages,
      files_url: updatedFiles,
      deleted_files: [...formData.deleted_files, ...deletedImgArr],
    });
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let data = new FormData;
    for (const item of formData.files) {
      data.append("files", item);
    }
    if (id) {
      for (const item of formData.deleted_files) {
        data.append("deleted_files", item);
      }
    }
    data.append("title", formData.title);
    data.append("filename", formData.filename);
    data.append('id', id ? id : null as any);

    id ? editObjectDetection(data) : createObjectDetection(data);

  };

  if (result.isSuccess && result.data.status === 200) {
    navigate("/object-detection");
  }

  if (editResult.isSuccess && editResult.data.status === 200) {
    navigate("/object-detection");
  }

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Object Detection File Uploader</strong>
          </CCardHeader>
          <CCardBody className="bg-transparent">
            <CForm onSubmit={handleSubmit}>
              <CRow className="g-3">
                <CCol xs={6}>
                  <CFormLabel htmlFor="boh-name">Title</CFormLabel>
                  <CFormInput
                    id="boh-name"
                    type="text"
                    placeholder="Title"
                    value={formData.title}
                    name="title"
                    feedbackInvalid="Please provide a Title."
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="boh-name">File Upload *</CFormLabel>
                  <CFormInput
                    id="files"
                    type="file"
                    placeholder="File Upload"
                    name="files"
                    accept=".tflite"
                    feedbackInvalid="Please provide a File."
                    required={formData.files.length > 0 ? false : true}
                    onChange={handleChangeImage}
                  />
                  {formData.filename !== '' ? <div className='imageDiv'>
                    <CIcon icon={cilFile} />
                    {formData.filename}
                  </div> : ''}
                </CCol>
                <CCol xs={12}>
                  <CButton type="submit" color="primary" className="px-4 m-2">
                    Submit
                  </CButton>
                  <Link className="btn btn-danger px-4" to="/object-detection">
                    Cancel
                  </Link>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default FileUploadEditor