import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} 
from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import {
  useDoLoginMutation,
  LoginRequest,
} from "../../../redux-services/api/LoginApi";
import AlertBox from "../../../components/AlertBox/AlertBox";
import { useDispatch } from "react-redux";
import { setAuth } from "../../../redux-services";

const Login: React.FunctionComponent = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [doLogin, result] = useDoLoginMutation();

  //state for taking login credentials
  const [formData, setFormData] = useState<LoginRequest>({
    email: "",
    password: "",
  });
  
  //  spread syntax ({...formData}), which copies all the properties and values of the current formData object.
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
                               
  //checking the authentication
  const loginHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }
    if (form.checkValidity()) {
      doLogin(formData);
    }
  };
  //states when credentials are correct and we navigate to dashboard 
  const [validated, setValidated] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState<boolean>(false);
  // 203 --> Non-Authoritative Information.
  // if (result.isSuccess && result.data.status === 203) {
  //   navigate("/");
  // }
  //200 --> Everything is OK
  if (result.isSuccess && result.data.status === 200) {
    dispatch(setAuth())
    navigate("/dashboard");
  }
  
  // function hides the password
  const isShow = (show: boolean) => {
    const status = show !== isRevealPwd;
    setIsRevealPwd(status);
  };
  
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">

      <CContainer>
        <CRow className="justify-content-center">
          
          <CCol md={5}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                <div style={{textAlign: "center"}}>
                <img src="qbr_logo.png" style={{maxWidth: "100%", height: "auto", display: "block", margin: "0 auto"}} />
                </div>
                  <CForm
                    noValidate
                    validated={validated}
                    onSubmit={loginHandler}>

                    <AlertBox />
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">
                      Sign In to your account
                    </p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        type="email"
                        placeholder="Username"
                        name="email"
                        feedbackInvalid="Please provid a valid email."
                        required
                        onChange={onChange}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        name="password"
                        type={isRevealPwd ? "text" : "password"}
                        placeholder="Password"
                        autoComplete="current-password"
                        required
                        feedbackInvalid="Please provide a valid password."
                        onChange={onChange}
                      />
                      <span onClick={() => isShow(true)}>
                        {isRevealPwd ? (
                          <VisibilityOffOutlined />
                        ) : (
                          <VisibilityOutlined />
                        )}
                      </span>
                      {/*  : <VisibilityOffOutlined />} */}
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton type="submit" color="primary"  className="px-4">
                          Login
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-end">
                        <Link to={"/forgot-password"}>Forgot password?</Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
