import CIcon from '@coreui/icons-react';
import { cilAddressBook, cilSpeedometer, cilUserPlus, cilApplications, cilCloudUpload } from '@coreui/icons';
import { CNavItem } from '@coreui/react';
import NotificationsIcon from '@mui/icons-material/Notifications';

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Users',
    to: '/users',
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'Support',
    to: '/support',
    icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      text: 'NEW',
    },
  },

  {
    component: CNavItem,
    name: 'Manage UI',
    to: '/manage-ui',
    icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Object Detection',
    to: '/object-detection',
    icon: <CIcon icon={cilCloudUpload} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Notification',
    to: '/topic',
    icon: <NotificationsIcon style={{ marginLeft: "5px", marginRight: "20px" }} />,
    badge: {
      color: 'info',
      text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Products',
    to: '/products',
    icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      text: 'NEW',
    },
  },
];

export default _nav;
