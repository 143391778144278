import { createApi } from '@reduxjs/toolkit/query/react';
import {
    ObjectDetectionListData,
    PageMeta,
    PaginatedData,
    Response,
} from '../../types';
import { axiosBaseQuery } from './AxiosBaseQuery';

export const ObjectDetectionApi = createApi({
    reducerPath: 'objectDetectionAPI',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['ObjectDetection'],
    endpoints: (builder) => ({

        objectDetectionList: builder.query<Response<PaginatedData<ObjectDetectionListData[]>>,PageMeta>({
            query: (pageData: PageMeta) => ({
                url: `/object-detection`,
                method: "GET",
                params: {
                    limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    order: pageData.order ?? process.env.REACT_APP_ORDER,
                    order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                    startDate: pageData.startDate ?? "",
                    endDate: pageData.endDate ?? "",
                },
            }),
            providesTags: ["ObjectDetection"],
        }),

        createObjectDetection: builder.mutation<Response<any>, FormData>({
            query: (data: FormData) => ({
                url: `/object-detection/create`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["ObjectDetection"],
        }),

        getObjectDetectionById: builder.query<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/object-detection/get/${id}`,
                method: "GET",
            }),
            providesTags: ["ObjectDetection"],
        }),

        editObjectDetection: builder.mutation<Response<any>, FormData>({
            query: (data: FormData) => ({
                url: `/object-detection/edit/${data.get("id")}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["ObjectDetection"],
        }),

        deleteObjectDetection: builder.mutation<Response<any>, string | undefined>({
            query: (id: string | undefined) => ({
                url: `/object-detection/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["ObjectDetection"],
        }),
    }),
});
export const {
    useObjectDetectionListQuery,
    useCreateObjectDetectionMutation,
    useGetObjectDetectionByIdQuery,
    useEditObjectDetectionMutation,
    useDeleteObjectDetectionMutation
} = ObjectDetectionApi;
